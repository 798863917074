import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./state/store";
import Datepicker from "@vuepic/vue-datepicker";
import Notifications from "@kyvg/vue3-notification";
import "@vuepic/vue-datepicker/dist/main.css";

import BootstrapVue3 from "bootstrap-vue-next";
import VueLazyLoad from "vue3-lazyload";
import VueApexCharts from "vue3-apexcharts";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const vClickOutside = require("click-outside-vue3");
import MoneySpinner from "v-money-spinner";
//import { registerScrollSpy } from "vue3-scroll-spy";
import { vMaska } from "maska";
import JSZip from "jszip";
import debugPlugin from "./helpers/debugPlugin";

import { i18n } from "./i18n";

import "sweetalert2/dist/sweetalert2.min.css";
import "@vueform/slider/themes/default.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import "../src/design/app.scss";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  CACHE_SIZE_UNLIMITED,
  getFirestore,
  initializeFirestore,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBCufi7uLHnBGVvInb6ixm2OSTaGH52D7M",
  authDomain: "clubpub-cb70b.firebaseapp.com",
  databaseURL: "https://clubpub-cb70b-default-rtdb.firebaseio.com",
  projectId: "clubpub-cb70b",
  storageBucket: "clubpub-cb70b.firebasestorage.app",
  messagingSenderId: "164092490488",
  appId: "1:164092490488:web:566af005425d1e8e3a010d",
  measurementId: "G-RD5QE9B89V",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});
export const db = getFirestore(app);

createApp(App)
  .use(store)
  .use(router)
  .use(BootstrapVue3)
  .use(Notifications)
  .use(MoneySpinner)
  .use(vClickOutside)
  .use(i18n)
  .use(JSZip)
  .use(debugPlugin, { debugMode: process.env.VUE_APP_DEBUG === "true" })
  .use(createPinia())
  //.use(registerScrollSpy)
  .use(VueApexCharts)
  .use(VueLazyLoad, {})
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  .use(require("vue-chartist"))
  .directive("maska", vMaska)
  .component("Datepicker", Datepicker)
  .mount("#app");
