/* eslint-disable @typescript-eslint/no-var-requires */
import { defineStore } from "pinia";
import Swal from "sweetalert2";
import { IRawMaterialsGroup } from "@/types/IRawMaterialsGroup";
import { getErrorMessage } from "@/helpers/errorStatement";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "@/main";
import { useUser } from "./auth";

export interface IRawMaterialsStateGroup {
  items: IRawMaterialsGroup[];
  newGroup: {
    name: string;
    obs: string;
    errored: boolean;
  };
  editGroup: {
    id: number | null;
    errored: boolean;
    name: string;
    obs: string;
  };
}

export const useRawMaterialsGroup = defineStore("rawMaterialsGroup", {
  state: (): IRawMaterialsStateGroup =>
    ({
      items: [],
      newGroup: {
        name: "",
        obs: "",
        errored: false,
      },
      editGroup: {
        id: null,
        name: "",
        obs: "",
        errored: false,
      },
    } as IRawMaterialsStateGroup),
  getters: {},
  actions: {
    async retrieveAllItems() {
      if (this.items.length === 0) {
        await (this as any).loadAllItems();
      }
      this.sortByAZ();
    },
    async loadAllItems(): Promise<any> {
      Swal.showLoading();
      const ref = collection(db, "raw-materials-groups");
      const user = await useUser().getCurrentUser();
      const docs = await getDocs(
        query(ref, where("empresaId", "==", user!.empresaId))
      );

      this.items = docs.docs.map((i) => {
        return { ...i.data(), id: i.id, uid: i.id };
      }) as any;
      Swal.close();
    },
    updateItemOnStore(item: IRawMaterialsGroup) {
      const index = this.items.indexOf(this.getRawMaterialGroupById(item.id));
      this.items[index].name = item.name;
      this.items[index].obs = item.obs;
    },
    getRawMaterialGroupById(id: number): IRawMaterialsGroup {
      const finded = this.items.find(
        (material) => material.id === id
      ) as IRawMaterialsGroup;
      if (!finded) {
        throw new Error("Não encontrado o item com esse ID " + id);
      }
      return finded;
    },
    sortByAZ() {
      this.items.sort((a: IRawMaterialsGroup, b: IRawMaterialsGroup) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    async createRawMaterialGroup(): Promise<IRawMaterialsGroup> {
      this.newGroup.errored = false;
      if (this.newGroup.name.length === 0) {
        this.newGroup.errored = true;
        throw new Error("Name is not valid");
      }
      Swal.showLoading();
      try {
        const user = await useUser().getCurrentUser();
        const query = await addDoc(collection(db, "raw-materials-groups"), {
          empresaId: user!.empresaId,
          name: this.newGroup.name,
          obs: this.newGroup.obs || "",
        });
        const doc = await getDoc(query);
        const data = { ...doc.data(), id: doc.id, uid: doc.id };
        this.items.push(data as any);
        this.newGroup.name = "";
        this.newGroup.obs = "";
        this.sortByAZ();
        Swal.close();
        return data as any;
      } catch (error: any) {
        Swal.fire("Oops!", getErrorMessage(error), "error");
        throw new Error(error);
      }
    },
    async editRawMaterialGroup(): Promise<IRawMaterialsGroup> {
      this.editGroup.errored = false;
      if (this.editGroup.name.length === 0) {
        this.editGroup.errored = true;
        throw new Error("Name is not valid");
      }
      if (this.editGroup.id === null) {
        throw new Error("ID is not valid");
      }
      Swal.showLoading();
      try {
        await setDoc(
          doc(db, "raw-materials-groups", this.editGroup.id as any),
          {
            name: this.editGroup.name,
            obs: this.editGroup.obs || "",
          },
          {
            merge: true,
          }
        );
        const updatedDoc = await getDoc(
          doc(db, "raw-materials-groups", this.editGroup.id as any)
        );
        const data = {
          ...updatedDoc.data(),
          id: updatedDoc.id,
          uid: updatedDoc.id,
        };
        this.updateItemOnStore(data as any);
        this.editGroup.id = null;
        this.editGroup.name = "";
        this.editGroup.obs = "";
        this.sortByAZ();
        Swal.close();
        return data as any;
      } catch (error: any) {
        Swal.fire("Oops!", getErrorMessage(error), "error");
        throw new Error(error);
      }
    },
    async deleteRawMaterialGroup(id: number): Promise<void> {
      Swal.showLoading();
      try {
        await deleteDoc(doc(db, "raw-materials-groups", id as any));
        this.items = this.items.filter((item) => item.id !== id);
        this.sortByAZ();
        Swal.close();
        return;
      } catch (error: any) {
        Swal.fire("Oops!", getErrorMessage(error), "error");
        throw new Error(error);
      }
    },
  },
});
