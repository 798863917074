import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "default",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/dashboards/default.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("./views/utility/404.vue"),
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "/:catchAll(.*)",
    redirect: "404",
  },
  {
    path: "/dashboard/blog",
    name: "blog-dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/blog/index.vue"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    meta: { authRequired: true },
    component: () => import("./views/calendar/calendar.vue"),
  },
  {
    path: "/pages/starter",
    name: "Starter",
    meta: { authRequired: true },
    component: () => import("./views/utility/starter.vue"),
  },
  {
    path: "/pages/maintenance",
    name: "Maintenance",
    meta: { authRequired: true },
    component: () => import("./views/utility/maintenance.vue"),
  },
  {
    path: "/pages/timeline",
    name: "Timeline",
    meta: { authRequired: true },
    component: () => import("./views/utility/timeline.vue"),
  },
  {
    path: "/pages/pricing",
    name: "Pricing",
    meta: { authRequired: true },
    component: () => import("./views/utility/pricing.vue"),
  },
  {
    path: "/pages/404",
    name: "Error-404",
    meta: { authRequired: true },
    component: () => import("./views/utility/404.vue"),
  },
  {
    path: "/pages/500",
    name: "Error-500",
    meta: { authRequired: true },
    component: () => import("./views/utility/500.vue"),
  },
  {
    path: "/email/templates/basic",
    name: "Email template basic",
    meta: { authRequired: true },
    component: () => import("./views/email/templates/basic.vue"),
  },
  {
    path: "/email/templates/alert",
    name: "Email template alert",
    meta: { authRequired: true },
    component: () => import("./views/email/templates/alert.vue"),
  },
  {
    path: "/tasks/list",
    name: "Task list",
    meta: { authRequired: true },
    component: () => import("./views/tasks/task-list.vue"),
  },
  {
    path: "/tasks/kanban",
    name: "Kanbanboard",
    meta: { authRequired: true },
    component: () => import("./views/tasks/kanbanboard.vue"),
  },
  {
    path: "/my-profile",
    name: "My Profile",
    meta: { authRequired: true },
    component: () => import("./views/profile/my-profile.vue"),
  },
  {
    path: "/minha-empresa",
    name: "Minha empresa",
    meta: { authRequired: true },
    component: () => import("./views/account/minha-empresa.vue"),
  },
  {
    path: "/configuracoes/contrato",
    name: "Contrato",
    meta: { authRequired: true },
    component: () => import("./views/account/contrato.vue"),
  },
  {
    path: "/settings/hours",
    name: "Horários de funcionamento",
    meta: { authRequired: true },
    component: () => import("./views/configuracoes/hours.vue"),
  },
  {
    path: "/configuracoes/importar",
    name: "Importar",
    meta: { authRequired: true },
    component: () => import("./views/configuracoes/importar.vue"),
  },
  {
    path: "/configuracoes/usuarios",
    name: "Usuarios",
    meta: { authRequired: true },
    component: () => import("./views/configuracoes/usuarios.vue"),
  },
  {
    path: "/configuracoes/integracoes",
    name: "Integracoes",
    meta: { authRequired: true },
    component: () => import("./views/configuracoes/integracoes.vue"),
  },
  {
    path: "/associados/empregadores",
    name: "Empregadores",
    meta: { authRequired: true },
    component: () => import("./views/empregadores/lista-empregadores.vue"),
  },
  {
    path: "/associados/empregadores/novo",
    name: "Novo empregador",
    meta: { authRequired: true },
    component: () => import("./views/empregadores/novo-empregador.vue"),
  },
  {
    path: "/associados",
    name: "Lista de associados",
    meta: { authRequired: true },
    component: () => import("./views/associados/lista.vue"),
  },
  {
    path: "/associados/novo",
    name: "Novo associado",
    meta: { authRequired: true },
    component: () => import("./views/associados/novo2.vue"),
  },
  {
    path: "/associados/editar",
    name: "Editar associado",
    meta: { authRequired: true },
    component: () => import("./views/associados/editar.vue"),
  },
  {
    path: "/associados/dependentes",
    name: "Lista de dependentes",
    meta: { authRequired: true },
    component: () => import("./views/dependentes/lista.vue"),
  },
  {
    path: "/associados/agregados",
    name: "Lista de agregados",
    meta: { authRequired: true },
    component: () => import("./views/agregados/Lista.vue"),
  },
  {
    path: "/associados/convites",
    name: "Lista de convidados",
    meta: { authRequired: true },
    component: () => import("./views/convidados/lista.vue"),
  },
  {
    path: "/associados/perfil-associado",
    name: "Perfil do associado",
    meta: { authRequired: true },
    component: () => import("./views/associados/perfil-associado.vue"),
  },
  {
    path: "/contas-receber",
    name: "Lista de contas à receber",
    meta: { authRequired: true },
    component: () => import("./views/contareceber/lista.vue"),
  },
  {
    path: "/contas-receber/nova",
    name: "Nova conta à receber",
    meta: { authRequired: true },
    component: () => import("./views/contareceber/novo.vue"),
  },
  {
    path: "/contas-pagar",
    name: "Lista de contas à pagar",
    meta: { authRequired: true },
    component: () => import("./views/contaspagar/lista.vue"),
  },
  {
    path: "/identidade-social",
    name: "Identidade Social",
    meta: { authRequired: true },
    component: () => import("./views/idsocial/view.vue"),
  },
  {
    path: "/convite",
    name: "Convite",
    meta: { authRequired: true },
    component: () => import("./views/convidados/convite.vue"),
  },
  {
    path: "/meuconvite",
    name: "Meu convite",
    meta: { authRequired: false },
    component: () => import("./views/convidados/meuconvite.vue"),
  },
  {
    path: "/transacoes",
    name: "Transações",
    meta: { authRequired: true },
    component: () => import("./views/transacoes/lista.vue"),
  },
  {
    path: "/validador",
    name: "Validador",
    meta: { authRequired: true },
    component: () => import("./views/validador/validador.vue"),
  },
  {
    path: "/concierge/patrons",
    name: "Frequentadores",
    meta: { authRequired: true },
    component: () => import("./views/validador/Patrons.vue"),
  },
  {
    path: "/boleto",
    name: "Boleto",
    meta: { authRequired: true },
    component: () => import("../components/boleto.vue"),
  },
  {
    path: "/contrato",
    name: "Imprimir contrato",
    meta: { authRequired: true },
    component: () => import("./views/associados/contrato.vue"),
  },
  {
    path: "/financeiro/boletos",
    name: "Boletos",
    meta: { authRequired: true },
    component: () => import("./views/transacoes/boletos.vue"),
  },
  {
    path: "/financeiro/fornecedores",
    name: "Fornecedores",
    meta: { authRequired: true },
    component: () => import("./views/fornecedores/lista.vue"),
  },
  {
    path: "/financeiro/contas-bancarias",
    name: "Contas bancárias",
    meta: { authRequired: true },
    component: () => import("./views/transacoes/contas-bancarias.vue"),
  },
  {
    path: "/financeiro/baixa-via-xml",
    name: "Baixa via XML",
    meta: { authRequired: true },
    component: () => import("./views/transacoes/baixa-via-xml.vue"),
  },
  {
    path: "/financeiro/categorias",
    name: "Categorias",
    meta: { authRequired: true },
    component: () => import("./views/transacoes/categorias.vue"),
  },
  // Estoque & Precificação
  {
    path: "/centro-de-custos",
    name: "Centro de Custos",
    meta: { authRequired: true },
    component: () => import("./views/estoque/CentroDeCustos.vue"),
  },
  {
    path: "/insumos",
    name: "Insumos",
    meta: { authRequired: true },
    component: () => import("./views/estoque/RawMaterials.vue"),
  },
  {
    path: "/insumos/input",
    name: "Edição e Criação de Insumos",
    meta: { authRequired: true },
    component: () => import("./views/estoque/InputRawMaterial.vue"),
  },
  {
    path: "/products",
    name: "Produtos",
    meta: { authRequired: true },
    component: () => import("./views/estoque/Products.vue"),
  },
  {
    path: "/products/input",
    name: "Edição e Criação de Produtos",
    meta: { authRequired: true },
    component: () => import("./views/estoque/InputProduct.vue"),
  },
  {
    path: "/financeiro/comissoes",
    name: "Comissões",
    meta: { authRequired: true },
    component: () => import("./views/transacoes/Comissoes.vue"),
  },
  {
    path: "/relatorios/associacoes",
    name: "Relatório de Associações",
    meta: { authRequired: true },
    component: () => import("./views/relatorios/Associacoes.vue"),
  },
  {
    path: "/dashboard-financeiro",
    name: "Dashboard Financeiro",
    meta: { authRequired: true },
    component: () => import("./views/relatorios/Financeiro.vue"),
  },

  // Restaurante e Bar
  {
    path: "/pub/tables",
    name: "Tables",
    meta: { authRequired: true },
    component: () => import("./views/pub/PDV.vue"),
  },
  {
    path: "/pub/start",
    name: "Start",
    meta: { authRequired: true },
    component: () => import("./views/pub/Start.vue"),
  },
  {
    path: "/pub/add-item",
    name: "Pub ADDItem",
    meta: { authRequired: true },
    component: () => import("./views/pub/AddItem.vue"),
  },
  {
    path: "/pub/invoices",
    name: "Comandas",
    meta: { authRequired: true },
    component: () => import("./views/pub/Invoices.vue"),
  },
  {
    path: "/pub/invoices/detail/:cashId/:invoiceId",
    name: "Detalhe da Comanda",
    meta: { authRequired: true },
    component: () => import("./views/pub/InvoiceDetail.vue"),
  },
  {
    path: "/pub/orders",
    name: "Status dos pedidos",
    meta: { authRequired: true },
    component: () => import("./views/pub/Orders.vue"),
  },
  {
    path: "/pub",
    name: "dashboard-pub",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/saas/index.vue"),
  },
  {
    path: "/pub/close-cash/:cashId",
    name: "close-cash",
    meta: { authRequired: true },
    component: () => import("./views/pub/CloseCash.vue"),
  },
  {
    path: "/pub/settings/",
    name: "Configurações do Bar",
    meta: { authRequired: true },
    component: () => import("./views/pub/Settings.vue"),
  },
  {
    path: "/pub/cashs-stat",
    name: "Histórico financeiro",
    meta: { authRequired: true },
    component: () => import("./views/pub/CashHistory.vue"),
  },
  {
    path: "/pub/closed-cash",
    name: "closed-cash",
    meta: { authRequired: true },
    component: () => import("./views/pub/ClosedCashHistory.vue"),
  },
  {
    path: "/pub/debits",
    name: "Débitos",
    meta: { authRequired: true },
    component: () => import("./views/pub/Debits.vue"),
  },
  {
    path: "/pub/settings/users",
    name: "Usuários do Bar",
    meta: { authRequired: true },
    component: () => import("./views/pub/PubUsers.vue"),
  },
];
export default routes;
