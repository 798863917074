export default {
  install(app: any, options: any) {
    // Adiciona uma função global `$debug` usando o método `app.config.globalProperties`
    app.config.globalProperties.$debug = function (message: any, ...args: any) {
      if (options.debugMode) {
        console.log(`[DEBUG] ${message}`, ...args);
      }
    };
  },
};

export function debug(message: string, ...args: any): void {
  if (process.env.VUE_APP_DEBUG == "true") {
    console.log(`[DEBUG] ${message}`, ...args);
  }
}
