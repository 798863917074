/* eslint-disable @typescript-eslint/no-unused-vars */
import { IEmpresa } from "@/types/IEmpresa";
import { IUser } from "@/types/IUser";
import { defineStore } from "pinia";
import { useEmpresa } from "./empresa";
import axios from "axios";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import router from "@/router";
import { useRawMaterialsGroup } from "./RawMaterialsGroup";

export interface IUserState {
  currentUser: null | IUser;
  currentToken: string | null;
  needsUpdate: boolean;
  loaded: boolean;
}

export interface IUserActions {
  setCurrentUser(user: IUser): IUser;
}

export const useUser = defineStore("user", {
  state: (): IUserState => ({
    currentUser: {
      pic: null,
      permissions: [],
      comissions: null,
      id: 0,
      empresaId: 0,
      email: "loading@gmail.com",
      name: "Carregando...",
      phone: "21999999999",
      password: "$2b$08$DkMmOoVzFI7ZQJsFy039HeLFw/gQP.Ry0/o5vg86w1S4iVT5Y3BXW",
      ignoreErrorInvited: false,
      createdAt: "2023-01-20T10:07:50.000Z" as any,
      updatedAt: "2023-07-26T22:59:40.000Z" as any,
      deletedAt: null as any,
    } as any,
    currentToken: localStorage.getItem("jwt"),
    needsUpdate: false,
    loaded: false,
  }),
  getters: {
    getCurrentToken(): string | null {
      return this.currentToken;
    },
  },
  actions: {
    async getCurrentUser(): Promise<IUser | null> {
      if (this.currentUser?.id === 0 || !this.currentUser) {
        const getToken = localStorage.getItem("jwt");
        if (getToken !== null) {
          Swal.showLoading();
          const { user } = await this.getLoginData(getToken);
          this.loaded = true;
          Swal.close();
          return user;
        } else {
          router.push("/login");
          return null;
        }
      } else {
        return this.currentUser as IUser;
      }
    },
    async getLoginData(jwt: string): Promise<{
      user: IUser;
      empresa: IEmpresa;
      token: string;
    }> {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          Swal.showLoading();
          const api = axios.create({
            baseURL: appConfig.ipServer,
            timeout: 15000,
            headers: { "x-access-token": jwt },
          });

          api.defaults.headers.common["Authorization"] = `${jwt}`;

          const response = await api.get("/login-data");
          Swal.close();
          if (response.data?.status == "failed") {
            localStorage.removeItem("jwt");
          }
          this.currentUser = response.data.user;
          const empresa = useEmpresa();
          empresa.setCurrentEmpresa(response.data.empresa);
          this.loaded = true;
          resolve({
            user: this.currentUser as any,
            empresa: empresa.currentEmpresa as any,
            token: this.currentToken as any,
          });

          const groups = useRawMaterialsGroup();
          await groups.loadAllItems();
        } catch (error) {
          localStorage.removeItem("jwt");
          router.push("/login?loginExpirado=true");
          reject(error);
        }
      });
    },
    setCurrentUser(user: IUser): IUser {
      this.currentUser = user;
      return user;
    },
    setCurrentToken(token: string): string {
      localStorage.setItem("jwt", token);
      this.currentToken = token;
      return token;
    },
    isLogged(): boolean {
      if (this.getCurrentUser() !== null) {
        return true;
      } else {
        return false;
      }
    },

    logOut(): void {
      localStorage.removeItem("jwt");
      this.currentUser = null;
      this.currentToken = null;
      return;
    },
  },
});
